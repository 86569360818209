import React from 'react';
import './App.css';
import { get_earned_achievements } from './Common';


class Achievements extends React.Component {
  render() {
    const earnedAchievements = get_earned_achievements(this.props.data);
    
    return (
      <div className="profile-wrapper">
        <div className="blog-inner">
          <div className="blog-header">
            <h1>Achievements</h1>
          </div>
          {
            earnedAchievements.map(a => {
              return (
                <div className="blogEntry infopanel-inner" key={`achievement-section-${a.name}`}>
                  <div className="title">{a.name}</div>
                  <div className="blog-body">
                    <img
                      src={`/img/${a.icon[a.prevTier]}.png`}
                      alt={`Icon for ${a.name} achievement`}
                      className={`achievement ${a.earnedTier > -1 ? '' : 'unearned'}`}
                    />
                    <div style={{ width: '100%' }}>

                      {`
                          ${a.earnedTier > -1 ? 'COMPLETE: ' : ''}
                          ${a.description.replace('{count}', a.count[a.prevTier])}
                        `}
                      <br />
                      {
                        a.earnedTier < a.count.length - 1
                          ? <div>{`${a.progress}/${a.count[a.earnedTier + 1]} towards next tier`}</div>
                          : ''
                      }

                      <div className="progress-bar-outer">
                        <div className="progress-bar-inner" id="dark-droids-progress" style={{ width: `${a.ratio}%` }}>
                          {`${a.ratio}%`}
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              )
            })
          }
        </div>
      </div >
    );
  }
}

export default Achievements;
