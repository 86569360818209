import React from 'react';
import './App.css';
import { MEDIA_TYPE_MAP, calc_sum, get_earned_achievements, URL, filter_media } from './Common';
import ListDisplayPanel from './ListDisplayPanel';

function user_rating_filter(data, media_type) {
  return data?.filter(x => x.format === media_type);
}

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 'selectedRows': 10 };
  }

  checkOverrideRatings() {
    const profile = window.location.href.split('/').at(-1);
    // when viewing another user's profile, we need to load their ratings
    if (!this.state.overrideRatings) {
      if (this.props.username !== undefined && profile && profile !== this.props.username) {
        fetch(`${URL}/user_rating`, {
          method: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          credentials: "include",
          body: JSON.stringify({
            username: profile,
          }),
        })
          .then(res => res.text())
          .then(res => {
            this.setState({ overrideRatings: JSON.parse(res) });
          })
          .catch(err => err);
      }
    }
    // if we switch the user's profile, wipe out the override ratings
    else if (this.state.overrideRatings && profile === this.props.username) {
      this.setState({ overrideRatings: null });
    }
  }

  componentDidUpdate() {
    this.checkOverrideRatings();

    // set ld+json tags, cannot be done in componentDidMount because props are null
    const profile = window.location.href.split('/').at(-1);
    const structuredDataText = {
      "@context": "https://schema.org",
      "@type": "ProfilePage",
      "mainEntity": {
        "@type": "Person",
        "name": profile || this.props?.username,
        "alternateName": profile || this.props?.username,
      },
    }
    const script = document.getElementById('custom-ld-json');
    script.textContent = JSON.stringify(structuredDataText);
  }

  componentDidMount() {
    this.checkOverrideRatings();
  }

  render() {
    let data = [];
    const profile = window.location.href.split('/').at(-1);
    
    if (this.props.data) {
      // delete any of the logged in users ratings when looking at someone else's profile
      if (this.state.overrideRatings) {
        this.props.data.sort((a, b) => a.media_id - b.media_id).forEach(x => {
          const newMedia = Object.assign({}, x);
          newMedia['user_rating'] = undefined;
          data.push(newMedia);
        });
        this.state.overrideRatings.forEach(x => {
          const index = x.media_id - 1;
          data[index].user_rating = x.user_rating;
        });
      }
      else {
        data = this.props.data.sort((a, b) => a.media_id - b.media_id);
      }
    }

    // calculate achievement progress before aggregating any data
    const earnedAchievements = get_earned_achievements(data);

    data = filter_media(data, ['collectionFilter', 'seasonFilter']);
    data = data?.filter(x => x.user_rating).sort((a, b) => (b.user_rating || 0) - (a.user_rating || 0));
    const user_rating_count = calc_sum(data, 'user_rating_count');

    // give the choice of seeing their top 10 or top 25 depending on number of ratings
    const seelctedRowsOptions = [10];
    if (user_rating_count > 100) {
      seelctedRowsOptions.push(25);
    }

    return (
      <div className="profile-wrapper">
        <div className="profile">
          <div className="profile-title">
            {profile || this.props?.username}
          </div>
          <div className='profile-title subtitle'>
            {`${user_rating_count} reviews`}
          </div>
          <div className='profile-title'>
            <div className='subtitle'>Achievements</div>
            <div className='profile-icons'>
              {
                earnedAchievements?.length > 0
                  ?
                  earnedAchievements.filter(a => a.earnedTier > -1).map(a => {
                    return (
                      <div className='tooltip' key={`achievement-${a.name}`}>
                        <div
                          className="image"
                          style={{ height: 'unset', margin: '5px' }}
                        >
                          <img
                            src={`/img/${a.icon[a.prevTier]}.png`}
                            style={{ 'height': '80px' }}
                            alt={`Icon for ${a.name} achievement`}
                          />
                        </div>
                        <div className="tooltip-text">
                          <div style={{fontSize: '16px'}}>{a.name}</div>
                          {a.description.replace('{count}', a.count[a.prevTier])}
                          {
                            a.earnedTier < a.count.length - 1
                              ? <div><br />{`${a.progress}/${a.count[a.earnedTier + 1]} towards next tier`}</div>
                              : ''
                          }
                        </div>

                      </div>
                    )
                  })
                  : <div style={{ fontSize: '16px' }}>
                    Submit more ratings to start earning achievements!
                    <br />For example, you can earn one by rating 8 episodes of The Mandalorian.
                  </div>
              }
            </div>
          </div>

          {
            seelctedRowsOptions?.length > 1 ?
              <div className='profile-panel' style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <button
                  className={`${this.state.selectedRows === 10 ? 'selected' : ''}`}
                  onClick={() => this.setState({ selectedRows: 10 })}
                >
                  Show Top 10
                </button>
                <button
                  className={`${this.state.selectedRows === 25 ? 'selected' : ''}`}
                  onClick={() => this.setState({ selectedRows: 25 })}
                >
                  Show Top 25
                </button>
              </div>
              : <div />
          }

          {
            Object.keys(MEDIA_TYPE_MAP).map(media_type => {
              return (
                <ListDisplayPanel
                  title={`My Top ${this.state.selectedRows} ${MEDIA_TYPE_MAP[media_type]}`}
                  data={user_rating_filter(data, media_type)}
                  valueField='user_rating'
                  key={`list-panel-${media_type}`}
                  selectedRows={this.state.selectedRows}
                >
                </ListDisplayPanel>
              )
            })
          }
        </div>
      </div >
    );
  }
}

export default Profile;
